import { API_HOST } from "constants/config";
import { ESTATE_MY_OVERDUE_LIST_URL, ESTATE_ROOM_OVERDUE_DETAIL_URL, } from "constants/urls";
import http from "network/http";
import { useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import { Card, Cell, Dialog, List, ListInstance, NavBar, PullRefresh, Toast } from "react-vant";
import { AppState } from "state";
import { useAppSelector } from "state/hooks";
import { Overdue } from "types";



declare type Page = {
  current: number, total: number
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  let navigate = useNavigate();
  const unmountedRef = useRef<boolean>(false);
  const listRef = useRef<ListInstance>(null);
  const [page, setPage] = useState<Page | null>(null);
  const [finished, setFinished] = useState<boolean>(false);
  const [list, setList] = useState<Array<Overdue>>([]);
  const [loading, setLoading] = useState(false)
  const { jwt } = useAppSelector((state: AppState) => state.user);
  const onLoadRefresh = async (isRefresh?: number) => {
    setLoading(true)

    var pageNumber = 1
    if (!isRefresh && page?.current) {
      pageNumber = page.current + 1
    }

    const res = await http.get(
      `${API_HOST}${ESTATE_MY_OVERDUE_LIST_URL}?page=${pageNumber}`,{headers:{"Authorization": 'Bearer ' + jwt}}
    )

    const results = res.data.results
    const _page = res.data.page
    setPage(_page)
    setList((v) => {
      const newList = isRefresh ? results : [...v, ...results];
      if (newList.length >= _page.total) {
        setFinished(true);
      }

      return newList;
    });
    setLoading(false)


  };

  const onRefresh = async () => {
    setFinished(false);
    await onLoadRefresh(1);
    listRef.current?.check();
  };

  useEffect(() => {

    return () => {
      unmountedRef.current = true;
    };
  }, []);

  const toPay = async (roomId: string, skid: string) => {
    var today = new Date()
    if (today.getDate() < 4) {
      Dialog.alert({
        message: '物业缴费功能在每月1,2,3号结算期间关闭；请谅解。',
      })
    } else {
      const res = await http.get(
        `${API_HOST}${ESTATE_ROOM_OVERDUE_DETAIL_URL}?skid=${skid}&room_id=${roomId}`,{headers:{"Authorization": 'Bearer ' + jwt}})
      if (res.status === 200) {
        if (res.data.code === "SUCCESS") {
          const url = `/order/overdue-detail?id=${res.data.data.id}`
          navigate(url)
        } else {
          Dialog.alert({
            message: `${res.data.data.msg}`,
          })
        }
      }

    }
  }

  return (
    <>
      <NavBar
        title="物业缴费"
        leftText="返回"
        onClickLeft={() => { navigate("/my") }}
      />
      {

      }
      <PullRefresh onRefresh={onRefresh} >
        <Cell.Group title={""}>
        </Cell.Group>
        <List ref={listRef} finished={finished} loading={loading} onLoad={onLoadRefresh} >
          {
            list?.map((item, i) => {
              return <Card key={i} style={{ margin: 10 }}>
                <Card.Body>
                  <Cell title={"房间"} value={item.room_code} />
                  <Cell title={"日期"} value={`${item.year}/${item.month}`} />
                  <Cell title={"金额"} value={item.amount} />
                  <Cell>
                    <div>费用明细</div>
                    {item.items.map((val) => { return <div style={{ textAlign: 'right' }} key={val.item_no}><span style={{ width: 150, display: 'inline-block', textAlign: 'left', float: "left" }}>{val.item_name}</span><span style={{ width: 100, display: 'inline-block' }}>{val.amount}</span></div> })}


                  </Cell>
                  <Cell title={`去缴费`} isLink={true} onClick={() => { toPay(item.room_id, item.skid) }} />
                </Card.Body>
              </Card>;
            })
          }
        </List>

      </PullRefresh>

    </>
  )
}



