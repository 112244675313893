import { useState } from "react";
import {  Stepper } from "react-vant";
import { Sku } from "types";
import "./styles.less";

interface Props {
  value: Sku | null;
  index:number;
  onChange:(val:number,index:number)=>void
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: Props) => {
  const [amount, setAmount] = useState(0);
  const { value } = props;

  return (
    <>
      {value ? (
    
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "5px 10px 5px 10px",
              
            }}
          >
            <span>{value.productName}</span>
            <div>
            <span style={{"textAlign":"right","paddingRight":"10px"}}>
              单价 {value.price} {"元"}
            </span>
            <span>
            {amount > value.stockNum ? (
                <span style={{ color: "red", paddingRight: "5px" }}>
                  库存不足
                </span>
              ) : null}{" "}
              数量
              <Stepper
                min={0}
                max={value.stockNum}
                value={amount}
                onChange={(v) => {
                  setAmount(parseInt(v.toString()));
                  props.onChange(parseInt(v.toString()),props.index)
                }}
              ></Stepper>{" "}

            </span>
            </div>
          </div>
   
      ) : null}
    </>
  );
};
