import { API_HOST } from "constants/config";
import { ESTATE_ROOM_BIND_REQUEST_URL} from "constants/urls";
import http from "network/http";
import { useState } from "react";
import {  useNavigate } from "react-router-dom";
import { Button, Card,   Field,  Form,  NavBar, Notify,} from "react-vant";
import { AppState } from "state";
import { useAppSelector } from "state/hooks";

// eslint-disable-next-line import/no-anonymous-default-export
export default function RoomBind() {
  let navigate = useNavigate();
  const [disabled,setDisabled]=useState(false)
  const [form] = Form.useForm();
  const { jwt } = useAppSelector((state: AppState) => state.user);
  const onFinish = (values: any) => {
    setDisabled(true)
    const url=`${API_HOST}${ESTATE_ROOM_BIND_REQUEST_URL}`
    http.post(url,{...values,project_id:'jna'},{headers:{"Authorization": 'Bearer ' + jwt}}).then(res=>{
      console.log(res)
      if(res.data.code==="ERROR"){
        Notify.show(res.data.msg)
        setDisabled(false)
      }else{
        navigate("/estate/my-room-list")
      }
    })
  };


  return (
    <>
 <NavBar
      title="房间绑定"
      leftText="返回"
 
      onClickLeft={() => {navigate("/estate/my-room-list") }}

    />
      
      <Card>
        <Card.Body>
      <Form
      form={form}
      showValidateMessage={false}
      onFinish={onFinish}
      footer={
        <div style={{ margin: '16px 16px 0' }}>
          <Button round nativeType="submit" type="primary" block disabled={disabled}>
            提交
          </Button>
        </div>
      }
    >
            <Form.Item  name="" label="城市" disabled>
        <Field value="珠海"  />
      </Form.Item>
      <Form.Item  name="" label="小区" disabled>
        <Field value="江南岸"  />
      </Form.Item>

      <Form.Item rules={[{ required: true, message: '请填写房间号码' }]} name="room_code" label="房间号码">
        <Field placeholder="请输入房间号码"/>
      </Form.Item>
      <Form.Item rules={[{ required: true, message: '请填写姓名' }]} name="nick_name" label="姓名">
        <Field placeholder="请输入姓名" />
      </Form.Item>

    </Form>
    </Card.Body>
    </Card>
    </>
  )
}



