import { configureStore , combineReducers} from '@reduxjs/toolkit'
import { emptySplitApi } from 'api/emptySplitApi'
import  { multiClientMiddleware } from 'redux-axios-middleware'
import { load, save } from 'redux-localstorage-simple'
import { clients } from './client'
import user from './user/reducer'
const PERSISTED_KEYS: string[] = ['user', 'transactions', 'lists']
const reducers=combineReducers({
    user:user,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  })
// const store = createStore(
//     reducers, //custom reducers
//     applyMiddleware(
//       //all middlewares
//       multiClientMiddleware(clients), //second parameter options can optionally contain onSuccess, onError, onComplete, successSuffix, errorSuffix

//     )
//   )

const middlewareConfig = {
  interceptors: {
    request: [
      function ({getState, dispatch, getSourceAction}: any, req: any) {
        if (getState().user?.jwt) {
          req.headers['Authorization'] = 'Bearer ' + getState().user?.jwt
      }
      return req
      }
    ],
  }
};


  const store = configureStore({
    reducer:reducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ thunk: true })
        .concat(multiClientMiddleware(clients,middlewareConfig))
       
        .concat(save({ states: PERSISTED_KEYS, debounce: 1000 })),
    preloadedState: load({ states: PERSISTED_KEYS, disableWarnings: process.env.NODE_ENV === 'test' }),
  })

export default store
// {value: 0}
export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch