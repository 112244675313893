import React from 'react';
import { Button, Field, Form, Popup } from 'react-vant';

interface Address {
    city: string
    area: string
    address: string
    contactName: string
    contactNumber: string
}


// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [form] = Form.useForm();

    const onFinish = () => {

    }

    return (
        <>
            <Form
                form={form}
                showValidateMessage={false}
                onFinish={onFinish}
                footer={
                    <div style={{ margin: '16px 16px 0' }}>
                        <Button round nativeType="submit" type="primary" block>
                            提交
                        </Button>
                    </div>
                }
            >
                <Form.Item>

                    <Field label="手机号" placeholder="请输入地址" />
                </Form.Item>
                <Form.Item>
                    <Field type="digit" label="联系人" placeholder="请输入联系人" /></Form.Item>
                <Form.Item>
                    <Field

                        type="number"
                        label="手机号码"

                        placeholder="请输入手机号码"
                    />

                </Form.Item>
            </Form>



        </>
    );
};

