import { API_HOST, HOST } from "constants/config";
import {  GET_ORDER_URL,LEDGER_BALANCE_URL, WEPAY_GET_PREPARE_ID } from "constants/urls";
import http from "network/http";
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Cell, Flex, NavBar, Notify } from "react-vant";
import { AppState } from "state";
import { useAppSelector } from "state/hooks";
import { OverdueOrder } from "types";





// eslint-disable-next-line import/no-anonymous-default-export
export default function OverdueDetail() {
    let navigate = useNavigate();
    const { search } = useLocation();
    const [item, setItem] = useState<OverdueOrder | null>(null);
    const [loading, setLoading] = useState(true)
    const [balance, setBalance] = useState('')
    const { jwt } = useAppSelector((state: AppState) => state.user);
    async function getData(id: string) {
        const res = await http.get(
            `${API_HOST}${GET_ORDER_URL}?id=${id}`,{headers:{"Authorization": 'Bearer ' + jwt}}
        )
        if (res.status===200) {
            setItem(res.data)
            setLoading(false)
        } else {
        }
    }

    async function getBalance(){
        const res = await http.get(
            `${API_HOST}${LEDGER_BALANCE_URL}`,{headers:{"Authorization": 'Bearer ' + jwt}}
        )   
        if(res.status===200){
            setBalance(res.data.balance)
        }
    }




    const payment = (orderId: string, orderMoney: number) => {

        http.get(
            `${API_HOST}${WEPAY_GET_PREPARE_ID}?order_id=${orderId}&total_price=${orderMoney}`,{headers:{"Authorization": 'Bearer ' + jwt}}
        ).then(function (res: { data: any; }) {
            var data = res.data
            if (data.prepay_id) {
                var next = encodeURIComponent( `${HOST}/order/overdue-detail?id=${orderId}`)
                window.location.href = `http://hlwx.sztitan.com/ledger/wxpay/pay/?next=${next}&prepay_id=${data.prepay_id}`;
            }
            else {
                Notify(data.message)
            }

        }, function (err: { response: { data: any; }; }) {
            console.log(JSON.stringify(err.response.data))
        })
    }
    useEffect(() => {
        console.log(search)
        const params = new URLSearchParams(search)
        const id = params.get("id")

        if (id) {
            getData(id)

        }
        getBalance()
    }, [search]);

    return (
        <>
         <NavBar
      title="物业缴费"
      leftText="返回"
 
      onClickLeft={() => {navigate("/estate/my-overdue-list") }}

    />
        <div style={{fontSize:"14px",textAlign:"right",margin:10,verticalAlign:"ceneter"}}>
        <Flex justify="end" align="center">
        <Flex.Item > <span style={{paddingRight:"4px",display:"inline-block"}}>账户余额: {balance}元</span> </Flex.Item>
        {/* <Flex.Item ><Button size="small" >充值</Button></Flex.Item> */}
      </Flex>
           </div>
        
        {item ?
            <Card style={{ margin: 10 }}>
                <Card.Body>
                    <Cell title={"收款ID"} value={item.id} />
                    <Cell title={"支付状态"} value={item.payment_status_display} />
                    {/* <Cell title={"处理状态"} value={item?.status} /> */}

                    <Cell title={"金额"} value={item.total_price} />

                    <div style={{ marginTop: 10 }}>
                        {item.payment_status === "new" || item.payment_status === "notpay"?
                            <Button type="primary" size="large" onClick={() => {payment(item.id,item.total_price) }}>
                                支付
                            </Button> : null}
                    </div>
                </Card.Body>
            </Card>
            : null}
        </>
    )
}
