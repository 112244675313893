import { useUpdateUsernameMutation } from "api/my_api";
import { API_HOST, HOST } from "constants/config";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Field, Flex, NavBar, Notify, Popup } from "react-vant";
import { AppState } from "state";
import { useAppSelector } from "state/hooks";
import { useUser } from "state/user/hooks";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

  const [username, setUsername] = useState("");

  const [updateUsername, { isLoading }] = useUpdateUsernameMutation();
  const {user}=useUser()
  let navigate = useNavigate();


  useEffect(()=>{
    setUsername(user.login_name)
  },[])

  async function onFinish() {
    if(username===""){
      Notify.show({ type: 'danger', message: "请输入用户名" });
    }
    try {
      const res = await updateUsername(username).unwrap()
      console.log(JSON.stringify(res))
      if (res.status === 'ok') {
        Notify.show({ type: 'success', message: '更新成功' });
        } else {
          Notify.show({ type: 'danger', message: res.msg });
        }
      } catch (err){
        Notify.show({ type: 'danger', message: err.data.msg });
      }
    }



  return (
    <>
      <NavBar
        title="修改用户名"
        leftText="返回"
        onClickLeft={() => {
          navigate("/my");
        }}
      />
       <div style={{padding:"5px"}}>

      <Field label="用户名" type="text" placeholder="请输入用户名"
      value={username}
      onChange={(val) => {
        setUsername(val)
      }}
      
      />
      <div style={{padding:"5px"}}>
      <Button type="primary" block round onClick={()=>{onFinish()}}>提交</Button>
      </div>
      </div>
    </>
  );
};


