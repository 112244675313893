import React, { useEffect } from 'react';
import { Flex, Grid } from 'react-vant';
import { Image } from 'react-vant';
import { Link, useNavigate } from 'react-router-dom';
import SpaceProductIcon from '../../assets/images/space-product.svg';
import BarrelledWatter from '../../assets/images/barrelled-water.svg';
import Tabbar from 'components/Tabbar';
import AdminPanel from 'components/AdminPanel';
import { useAppDispatch } from 'state/hooks';
import { get_current_user } from 'state/user/reducer';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch()
  useEffect(()=>{
    dispatch(get_current_user())
  },[dispatch])

  return (
    <div >

<Grid>

<Grid.Item icon={<Image src={SpaceProductIcon} />} text='羽毛球订场' onClick={()=>{navigate("/shop/space-product/book")}}/> 


      <Grid.Item icon={<Image src={BarrelledWatter} />}  text='饮用水' onClick={()=>{navigate("/shop/water/3/")}} />


    </Grid>

      <AdminPanel />
      <Tabbar />

    </div>
  );
};