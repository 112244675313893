import { Card, Flex } from 'react-vant';
import { OrderItem } from 'types';
import './styles.less';

interface Props {
    value: OrderItem | null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: Props) => {
    const { value } = props
    return (
        <>
          {value?<Card className='card' style={{paddingBottom:"10px"}}>
          <div><span>{value.name}</span></div>
              <Flex justify="start" align="start">
              <Flex.Item> <p  style={{paddingRight:10}}>数量:{value.quantity}</p> </Flex.Item>
             {value.goodSkuValues? <Flex.Item> <p  style={{paddingRight:10}}>规格:{value.goodSkuValues}</p> </Flex.Item>:null}

              </Flex>
          </Card>:null}
        </>
    );
};
