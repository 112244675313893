import React, { useEffect } from 'react';
import { Tabbar } from 'react-vant';
import { HomeO, UserO } from '@react-vant/icons';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppState } from 'state';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { TABBAR_INDEX_CHANGE } from 'state/user/actions';


// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const { jwt, user } = useAppSelector((state: AppState) => state.user)
    let navigate = useNavigate()
    const { tabbarIndex } = useAppSelector((state: AppState) => state.user)
    let location = useLocation();
    const dispatch = useAppDispatch()

    const pages = ["/", "/my"]

    useEffect(()=>{
        var index=0
        if(location.pathname==="/"){
            index=0
        }else if(location.pathname==="/my"){
            index=1
        }
        dispatch({ type:TABBAR_INDEX_CHANGE, payload: { tabbarIndex: index  } });
    },[dispatch, location.pathname])

    const onChange = (v:number) => {
        dispatch({ type:TABBAR_INDEX_CHANGE, payload: { tabbarIndex: v  } });
        navigate(pages[v])
    }

    return (

        <Tabbar value={tabbarIndex} onChange={(v)=>{onChange(v as number)}}safeAreaInsetBottom={true} >
            <Tabbar.Item icon={<HomeO />}>首页</Tabbar.Item>
            <Tabbar.Item icon={<UserO />}>我的</Tabbar.Item>
        </Tabbar>
    );
};