import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import Address from "components/Address";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Cell, Form, Loading, Notify, Popup, Field } from "react-vant";
import { Card } from "react-vant";
import { Good, OrderGood, ShippingAddress, Sku } from "types";

import {
  GOOD_DETAIL_URL,
  SHOP_ORDER_URL,
  SHOP_ADDRESS_GET_DEFAULT,
  SHOP_ORDER_CREATE,
  NEW_ORDER_ID_URL,
  ADDRESS_DEFAULT_URL,
} from "../../../constants/urls";
import http from "../../../network/http";
import { API_HOST, GOOD_HOST } from "constants/config";
import { useAppSelector } from "state/hooks";
import { AppState } from "state";
import SkuProductInput from "components/SkuProductInput";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [good, setGood] = useState<Good | null>(null);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [skuList, setSkuList] = useState<Sku[]>([]);
  const [showAddressEdit, setShowAddressEdit] = useState(false);
  const [address, setAddress] = useState<ShippingAddress | null>(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [orderQuantity, setOrderQuantity] = useState<number[]>([]);
  const [orderGoods,setOrderGoods]=useState<OrderGood[]>([])

  const { jwt } = useAppSelector((state: AppState) => state.user);
  useEffect(() => {
    setIsLoading(true);
    const url = `${GOOD_HOST}${GOOD_DETAIL_URL}?goodId=${id}`;
    http
      .get(url, { headers: { Authorization: "Bearer " + jwt } })
      .then((res: AxiosResponse<any>) => {
        const result: Good = res.data;

        var _skuList: Sku[] = result.goodSkuList;
        for (var j = 0; j < result.bundling.length; j++) {
          _skuList = _skuList.concat(result.bundling[j].good.goodSkuList);
        }
        setSkuList(_skuList);


        setGood(result);
        setIsLoading(false);
      })
      .catch((err) => {
        console.info(err);
        setIsLoading(false);
      });
  }, [id, jwt]);

  useEffect(() => {
    const url = `${GOOD_HOST}${SHOP_ADDRESS_GET_DEFAULT}`;
    http
      .get(url, { headers: { Authorization: "Bearer " + jwt } })
      .then((res: AxiosResponse<any>) => {
        if (!res.data.phone) {
          http
            .get(`${API_HOST}${ADDRESS_DEFAULT_URL}`, {
              headers: { Authorization: "Bearer " + jwt },
            })
            .then((res: AxiosResponse<any>) => {
              setAddress(res.data);
            });
        } else {
          setAddress(res.data);
        }
      });
  }, []);

  const onFinish = async () => {
    setDisableSubmit(true);
    const valid = validate();
    if (valid !== true) {
      return;
    }
    const url = `${GOOD_HOST}${SHOP_ORDER_CREATE}`;
    const new_order_id_res = await http.get(`${API_HOST}${NEW_ORDER_ID_URL}`, {
      headers: { Authorization: "Bearer " + jwt },
    });
    const order_id = new_order_id_res.data.order_id;
    const data = {
      goods: orderGoods,
      shippingAddress: address,
      totalPrice: totalPrice,
      pickupWay: "home-delivery",
      id: order_id,
    };
    const res = await http.post(url, data, {
      headers: { Authorization: "Bearer " + jwt },
    });
    if (res.status === 200) {
      const data = { totalPrice: totalPrice, detail: res.data, orderId: order_id };
      const res2 = await http.post(`${API_HOST}${SHOP_ORDER_URL}`, data, {
        headers: { Authorization: "Bearer " + jwt },
      });
      if (res2.status === 200) {
        if (res2.data.code === "SUCCESS") {
          navigate(`/shop/order/${res2.data.data.order_id}`);
        } else {
          Notify.show("出错了");
        }
      }
    }
    setDisableSubmit(false);
  };

  const onFinishAddress = () => {
    var newAddress = { ...address, ...form.getFieldsValue() };
    setAddress(newAddress);
    setShowAddressEdit(false);
  };
  const handleSkuAmountChange = (val: number,index:number) => {
    var newList: number[]=[]
     Object.assign(newList,orderQuantity)
     newList[index]=val
     setOrderQuantity(newList)  
  };


  useEffect(()=>{
    var totalPrice=0
    var orderGoods:OrderGood[]=[]
    skuList.forEach((sku,index)=>{
        if(orderQuantity[index]){
            totalPrice=totalPrice+orderQuantity[index]*sku.price
            orderGoods.push({
                skuId: sku.id,
                productId: good? good.id:"",
                name: sku?sku.productName:"",
                skuValues: sku.skuValues,
                quantity: orderQuantity[index]
            })
        }
    })
    setOrderGoods(orderGoods)
    setTotalPrice(totalPrice)

  },[good, orderQuantity, skuList])

  const validate = () => {
    // if (!sku) {
    //     Notify.show("请选择商品规格")
    //     return false
    // }
    // if (stockNumber<amount) {
    //     Notify.show("库存不足")
    //     return false
    // }
    // if (!salePrice) {
    //     Notify.show("请选择商品")
    //     return false
    // }
    // if (!amount) {
    //     Notify.show("请选择商品数量")
    //     return false
    // }
    if (!totalPrice) {
        Notify.show("价格计算出错")
        return false
    }
    return validateAddress();
  };

  const validateAddress = () => {
    if (!address) {
      Notify.show("请填写收货地址");
      return false;
    }
    if (!address.consignee) {
      Notify.show("请填写收货人");
      return false;
    }
    if (!address.address) {
      Notify.show("请填写收货地址");
      return false;
    }
    if (!address.phone) {
      Notify.show("请填写联系电话");
      return false;
    }
    return true;
  };

  return (
    <>
      {isLoading ? (
        <Loading color="#3f45ff" style={{ padding: "8px" }} />
      ) : (
        <Cell.Group title={good?.name}>
          {good?.salesStatus === "notsales" ? (
            <Card style={{ margin: 5 }}>
              <Cell>产品未上架</Cell>
            </Card>
          ) : (
            <Card style={{ margin: 5 }}>
              {skuList.map((sku, i) => {
                return (
                  <SkuProductInput
                    value={sku}
                    onChange={handleSkuAmountChange}
                    index={i}
                  />
                );
              })}

              <Cell>
                <div style={{ textAlign: "right", fontSize: 16 }}>
                  合计：{totalPrice ? totalPrice : "N/A"}元
                </div>
              </Cell>

              <Cell>
                <div>
                  <span>收货地址：</span>
                  <span style={{ float: "right" }}>
                    <Button
                      onClick={() => {
                        setShowAddressEdit(true);
                      }}
                      plain
                      type="info"
                      size="mini"
                    >
                      编辑
                    </Button>
                  </span>
                </div>
                <Address value={address} />
              </Cell>

              <div style={{ padding: 15 }}>
                <Button
                  block
                  type="primary"
                  color="linear-gradient( 135deg, #3f45ff 10%, #7367F0 100%)"
                  onClick={() => {
                    onFinish();
                  }}
                >
                  立即购买
                </Button>
              </div>
            </Card>
          )}
        </Cell.Group>
      )}
      <Popup
        visible={showAddressEdit}
        style={{ height: 360 }}
        position="bottom"
        onClose={() => {
          setShowAddressEdit(false);
        }}
        round
      >
        <Form
          form={form}
          showValidateMessage={false}
          onFinish={onFinishAddress}
          footer={
            <div style={{ margin: "16px 16px 0" }}>
              <Button
                round
                nativeType="submit"
                type="primary"
                block
                disabled={disableSubmit}
              >
                提交
              </Button>
            </div>
          }
        >
          <Card>
            <Card.Header>收货地址</Card.Header>
            <Card.Body>
              <Form.Item
                required
                name="address"
                initialValue={address?.address}
              >
                <Field label="地址" placeholder="请输入地址" />
              </Form.Item>
              <Form.Item
                required
                name="consignee"
                initialValue={address?.consignee}
              >
                <Field type="text" label="联系人" placeholder="请输入联系人" />
              </Form.Item>

              <Form.Item required name="phone" initialValue={address?.phone}>
                <Field
                  type="number"
                  label="手机号码"
                  placeholder="请输入手机号码"
                />
              </Form.Item>
            </Card.Body>
          </Card>
        </Form>
      </Popup>
    </>
  );
};
