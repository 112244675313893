
import { GET_CURRENT_USER_URL, LOGIN_URL, WEIXIN_LOGIN_URL } from "../../constants/urls"

import { Action } from './actions';
import * as Actions from './actions';
import { User } from "types";
import { AppState } from "state";



export interface UserState {
    user: User | null,
    jwt: string | null,
    status: "idle" | "loading" | 'succeeded' | 'failed',
    loginError: string | null

    tabbarIndex: number

}

export function login(payload: { username: string, password: string }): Action {
    return {
        type: Actions.LOGIN,
        payload: {
            client: 'auth',
            request: {
                url: LOGIN_URL,
                method: "POST",
                data: payload
            }
        }
    }
}

export function get_current_user(): Action {

    return {
        type: Actions.GET_CURRENT_USER,
        payload: {
            client: 'default',
            request: {
                url: GET_CURRENT_USER_URL,
                method: "GET"
            }
        }
    }
}


export function weixinlogin(payload: { code: string }): Action {
    return {
        type: Actions.WEIXIN_LOGIN,
        payload: {
            client: 'default',
            request: {
                url: `${WEIXIN_LOGIN_URL}?code=${payload.code}`,
                method: "GET"
            }
        }
    }
}


export const reducer = (state: UserState = {
    user: null,
    jwt: null,
    status: "idle",
    loginError: null,
    tabbarIndex: 0

}, action: Action) => {
    switch (action.type) {
        case Actions.LOGIN_SUCCESS:   //对应的是LOGIN 返回SUCCESS状态的处理，也就是http status=200, redux 封装了。login action -> login_success, login_fail
            // localStorage.setItem("jwt", action.payload.data["jwt"])
            return {
                ...state,
                jwt: action.payload.data["jwt"],
                user:action.payload.data["user"]
            };
        case Actions.LOGIN_FAIL:
            return {
                ...state,
                loginError: action.error.response.data
            };
        case Actions.WEIXIN_LOGIN_SUCCESS:
            const status = action.payload.data["status"]
            if (status === "ok") {
                const data = action.payload.data["data"]
                // localStorage.setItem("jwt", data["jwt"])
                //alert( data["jwt"])
                return {
                    ...state,
                    jwt: data["jwt"],
                    user:data["user"]
                };
            } else {
                return {
                    ...state,
                    loginError: action.payload.data["message"]
                }
            };
        case Actions.TABBAR_INDEX_CHANGE:
            return {
                ...state,
                ...action.payload
            };

        case Actions.GET_CURRENT_USER_SUCCESS:
            // if(state.jwt){
            //     localStorage.setItem("jwt",state.jwt )
            // }
            
            return {
                ...state,
                user:{...action.payload.data}
            };
        case Actions.GET_CURRENT_USER_FAIL:
            if(action.error.response.status===401){
                // localStorage.removeItem("jwt")
                return {
                    ...state,
                    user:null,
                    jwt:null
                }
            }
            return {
                ...state,
            };

        default: return state;
    }
};
export const selectCurrentUser = (state: AppState) => state.user.user;
export default reducer