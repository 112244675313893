
import { AxiosResponse } from 'axios';
import { API_HOST, HOST } from 'constants/config';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Cell, CountDown, CountDownInstance, Flex, Loading, Notify, Toast, Typography } from 'react-vant';
import { Dialog } from 'react-vant';
import { Card } from 'react-vant';
import { AppState } from 'state';
import { useAppSelector } from 'state/hooks';
import {SHOP_SPACE_PRODUCT_ORDER_DETAIL_URL, WEPAY_GET_PREPARE_ID } from '../../../constants/urls';
import http from '../../../network/http';
declare const window: Window & { WeixinJSBridge: any };

interface Order {
  id: string,
  title: string,
  payment_expired_at: Date,
  timeList: String[],
  total_price: number,
  payment_status: string,
  payment_status_display: string,
  memo: string
}

interface BookInfo {
  name: string,
  bookDate: string,
  bookTime: string[],
  productName: string,
  contactName: string,
  contactNumber: string
}
declare type MutilRowFlexProps = {
  data: string[]
  title: string
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false)

  const [order, setOrder] = useState<Order | null>(null)
  const [bookInfo, setBookInfo] = useState<BookInfo | null>(null)
  const [expireTime, setExpireTime] = useState<number>(0)
  const [showCountDown, setShowCountDown] = useState(true)
  const { jwt } = useAppSelector((state: AppState) => state.user);

  useEffect(() => {
    setIsLoading(true)
    http.get(`${API_HOST}${SHOP_SPACE_PRODUCT_ORDER_DETAIL_URL}/${id}`,{headers:{"Authorization": 'Bearer ' + jwt}}).then((res: AxiosResponse<any>) => {
      const result = res.data
      console.log("result:", result)
      console.log("result[\"code\"]:", result["code"])
      if (result["code"] == "FAIL") {
        const msg = result["message"]
        setIsLoading(false)
      } else if (result["code"] == "SUCCESS") {
        const order: Order = result["data"]["order"]
        setOrder(order)
        setBookInfo(result["data"]["detail"]["data"])
        const timettt = moment(order.payment_expired_at).toDate().getTime() - new Date().getTime()
        setExpireTime(timettt)
        if (order.payment_status != "paid") {
          setShowCountDown(true)
        }
      }

    })
  }, [id])



  const payment = (orderId: string, orderMoney: number) => {

    http.get(
      `${API_HOST}${WEPAY_GET_PREPARE_ID}?order_id=${orderId}&total_price=${orderMoney}`,{headers:{"Authorization": 'Bearer ' + jwt}}
    ).then(function (res: { data: any; }) {
      var data = res.data
      if (data.prepay_id) {
        var next = `${HOST}${SHOP_SPACE_PRODUCT_ORDER_DETAIL_URL}/${orderId}`
        window.location.href = `http://hlwx.sztitan.com/ledger/wxpay/pay/?next=${next}&prepay_id=${data.prepay_id}`;
      }
      else {
        Notify(data.message)
      }

    }, function (err: { response: { data: any; }; }) {
      console.log(JSON.stringify(err.response.data))
    })

  }
  const MutilRowFlex = (props: MutilRowFlexProps) => {
    return (
      <>

        <div className='rv-cell__title' style={{ padding: "10px 16px 0 16px" }}>{props.title}</div>
        <div style={{ color: "#969799" }} className={"rv-cell"}>

          <Flex gutter={4} wrap={"wrap"} justify={"end"} style={{ fontFamily: 'Helvetica Neue' }}>{props.data.map((val, index) => { return <Flex.Item span={8} key={index} style={{ textAlign: "right" }} >{val}</Flex.Item> })}</Flex>

        </div>
      </>

    )
  }

  return (

    <div>
      {order ?
        <Card style={{ margin: 8 }}>
          <Card.Header> <Typography.Title level={3}>订单信息</Typography.Title></Card.Header>
          <Card.Body>

            <Cell title="订单ID" value={order.id} />
            <Cell title="订单金额" value={`${order?.total_price.toFixed(2)}元`} />
            <Cell title="付款状态" value={order?.payment_status_display} />

            <Cell title="场馆" value={bookInfo?.productName} />
            <Cell title="预定日期" value={bookInfo?.bookDate} />

            {bookInfo?.bookTime.length == 1 ?
              <Cell title={"场次"} value={bookInfo?.bookTime} />
              : null}
            {bookInfo && bookInfo.bookTime.length > 1 ?
              <MutilRowFlex data={bookInfo?.bookTime} title={'场次'} /> : null}
            <Cell title="联系人" value={bookInfo?.contactName} />
            <Cell title="电话号码" value={bookInfo?.contactNumber} />
            {showCountDown ?
              <CountDown time={expireTime} millisecond format="mm:ss" onFinish={() => { setShowCountDown(false) }}>
                {(timeData) => (
                  <>
                    <Typography.Title level={4} style={{ padding: 20 }} type="danger">
                      剩余支付时间： {timeData.minutes}分{timeData.seconds}秒
                    </Typography.Title>
                    <Button type="primary" size="large" onClick={() => {
                      Dialog.confirm({
                        title: '付款须知',
                        message: '场次预定一经确认后不可取消，不可退！您是否确认提交？',
                      }).then(val => {
                        if (val === true) {
                          payment(order.id, order.total_price)
                        }
                      })

                    }


                    }>
                      微信支付
                    </Button>

                  </>
                )}
              </CountDown> : null}

            {order.memo ?

              <Cell title={order.memo} style={{ color: "red" }} center />
              : null
            }

          </Card.Body>
        </Card> : <Loading color="#3f45ff" style={{ padding: "8px" }} />}

    </div>
  );
};




