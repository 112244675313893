import { API_HOST, HOST } from "constants/config";
import { GET_ORDER_URL, WEPAY_GET_PREPARE_ID } from "constants/urls";
import http from "network/http";
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import {  Button, Card, Cell,  NavBar, Notify } from "react-vant";
import { AppState } from "state";
import { useAppSelector } from "state/hooks";
import { RechargeOrder } from "types";





// eslint-disable-next-line import/no-anonymous-default-export
export default function OverdueDetail() {
    let navigate = useNavigate();
    const { search } = useLocation();
    const [item, setItem] = useState<RechargeOrder | null>(null);
    const [loading, setLoading] = useState(true)
    const { jwt } = useAppSelector((state: AppState) => state.user);

    async function getData(id: string) {
        const res = await http.get(
            `${API_HOST}${GET_ORDER_URL}?id=${id}`,{headers:{"Authorization": 'Bearer ' + jwt}}
        )
        if (res.status===200) {
            setItem(res.data)
            setLoading(false)
        } else {
        }
    }



    const payment = () => {

        http.get(
            `${API_HOST}${WEPAY_GET_PREPARE_ID}?order_id=${item?.id}&total_price=${item?.total_price}`,{headers:{"Authorization": 'Bearer ' + jwt}}
        ).then(function (res: { data: any; }) {
            var data = res.data
            if (data.prepay_id) {
                var next = encodeURIComponent(`${HOST}/order/recharge-order-detail?id=${item?.id}`)
                window.location.href = `http://hlwx.sztitan.com/ledger/wxpay/pay/?next=${next}&prepay_id=${data.prepay_id}`;
            }
            else {
                Notify(data.message)
            }

        }, function (err: { response: { data: any; }; }) {
            console.log(JSON.stringify(err.response.data))
        })
    }
    useEffect(() => {
        console.log(search)
        const params = new URLSearchParams(search)
        const id = params.get("id")
        id && getData(id)
    }, [search]);

    return (
        <>
            <NavBar
                title="充值"
                leftText="返回"

                onClickLeft={() => { navigate("/my/account-balance") }}

            />

            {item ?
                <Card style={{ margin: 10 }}>
                    <Card.Body>
                        <Cell title={"订单"} value={item?.id} />
                        <Cell title={"支付状态"} value={item?.payment_status_display} />
                        {/* <Cell title={"处理状态"} value={item?.status} /> */}
                        <Cell title={"金额"} value={item?.total_price} />
      
                        <div style={{ marginTop: 10 }}>
                            {item.payment_status === "new" || item.payment_status === "wait-pay"  ?
                                <Button type="primary" size="large" onClick={() => { payment()}}>
                                    支付
                                </Button> : null}
                        </div>
                    </Card.Body>
                </Card>
                : null}
        </>
    )
}
