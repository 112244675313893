import OrderItemCard from 'components/OrderItemCard';
import { Order } from 'types';


interface Props {
    value: Order
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: Props) => {
    const { value } = props
    return (
        <>
         {value.detail.orderItems?.map((val)=>{return <OrderItemCard value={val} key={val.id} />})}
        </>
    );
};
