import { Card, Flex } from 'react-vant';
import { OrderItem } from 'types';
import './styles.less';

interface Props {
    value: OrderItem | null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: Props) => {
    const { value } = props
    return (
        <>
          {value?<Card className='card' style={{paddingBottom:"10px"}}>
          <div><span>{value.name} {value.goodSkuValues}</span></div>
              <Flex justify="start" align="start">

              <Flex.Item><span style={{paddingRight:10}}>单价:{value.unitPrice} {value.priceUnit}</span></Flex.Item>
              <Flex.Item> <span  style={{paddingRight:10}}>数量:{value.quantity}</span> </Flex.Item>
             {value.goodSkuValues? <Flex.Item> <span  style={{paddingRight:10}}>规格:{value.goodSkuValues}</span> </Flex.Item>:null}
              <Flex.Item> <span>总价:{value.totalPrice} 元</span> </Flex.Item>
              </Flex>
          </Card>:null}
        </>
    );
};
