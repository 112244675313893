import OrderDetail from "components/OrderDetail";
import { API_HOST } from "constants/config";
import {  SHOP_ORDER_URL } from "constants/urls";
import { wrap } from "module";
import http from "network/http";
import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom";
import { Card, Cell, Divider, Flex, List, ListInstance, PullRefresh, Toast } from "react-vant";
import { AppState } from "state";
import { useAppSelector } from "state/hooks";
import { Order } from "types";



declare type Page = {
  current: number, total: number
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const unmountedRef = useRef<boolean>(false);
  const listRef = useRef<ListInstance>(null);
  const [page, setPage] = useState<Page | null>(null);
  const [finished, setFinished] = useState<boolean>(false);
  const [list, setList] = useState<Array<Order>>([]);
  const [loading, setLoading] = useState(false)
  const { jwt } = useAppSelector((state: AppState) => state.user);

  const onLoadRefresh = async (isRefresh?: number) => {
    setLoading(true)
    console.log("isRefresh:", isRefresh)
    console.info("onLoadRefresh call")
    var pageNumber = 1
    if (!isRefresh && page?.current) {
      pageNumber = page.current + 1
    }

    const res = await http.get(
      `${API_HOST}${SHOP_ORDER_URL}?page=${pageNumber}`,
      {headers:{"Authorization": 'Bearer ' + jwt}}
    )

    const results = res.data.results
    const _page = res.data.page
    setPage(_page)
    setList((v) => {
      const newList = isRefresh ? results : [...v, ...results];
      if (newList.length >= _page.total) {
        setFinished(true);
      }

      return newList;
    });
    setLoading(false)


  };

  const onRefresh = async () => {
    setFinished(false);
    await onLoadRefresh(1);
    listRef.current?.check();
  };

  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  return (
    <>

      <PullRefresh onRefresh={onRefresh} >
        <Cell.Group title={"我的订单"}>
        </Cell.Group>
        <List ref={listRef} finished={finished} loading={loading} onLoad={onLoadRefresh} >
          {
            list?.map((item, i) => {
              return <Card key={i} style={{ marginBottom: 10 }}>
                <Card.Body>
                  <Cell title={"订单ID"} value={item.id} />
                  <Cell title={"订单金额"} value={item.total_price} />
                  <Cell title={"订单状态"} value={item.payment_status_display} />
                  <Cell>
                        <div>商品明细</div>
                        <OrderDetail value={item} /> 
                         </Cell>  
                         <Link to={`/shop/order/${item.id}`}><Cell  title={`查看详情`} isLink={true}/></Link>
                </Card.Body>
              </Card>;
            })
          }
        </List>

      </PullRefresh>

    </>
  )
}



