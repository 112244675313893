import Tabbar from 'components/Tabbar';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Cell, Dialog, } from "react-vant";
import { clear } from 'redux-localstorage-simple'

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  let navigate = useNavigate();

  const clearData=()=>{
    clear();
    localStorage.removeItem("jwt");
    navigate("/")
  }
  return (
    <div>

      <Cell.Group title="羽毛球馆"  >
        <Cell title="羽毛球馆订场" isLink={true} size="large" onClick={() => { navigate("/shop/space-product/book") }} />
        <Cell title="订场历史" isLink={true} size="large" onClick={() => { navigate("/shop/space-product/order") }} />
      </Cell.Group>

      <Cell.Group title="购物"  >
        <Cell title="我的订单" isLink={true} size="large" onClick={() => { navigate("/shop/order") }} />
      </Cell.Group>

      <Cell.Group title="物业"  >
      
        <Cell title="物业缴费" isLink={true} size="large" onClick={() => { navigate("/estate/my-overdue-list") }} />
        <Cell title="缴费记录" isLink={true} size="large" onClick={() => { navigate("/estate/my-order") }} />
        <Cell title="房间绑定" isLink={true} size="large" onClick={() => { navigate("/estate/my-room-list") }} />
        <Cell title="余额" isLink={true} size="large" onClick={() => { navigate("/my/account-balance") }} />
        
      </Cell.Group>

      <Cell.Group title="设置"  >
      <Cell title="修改密码" isLink={true} size="large" onClick={() => { navigate("/my/password") }} />
      <Cell title="修改用户名" isLink={true} size="large" onClick={() => { navigate("/my/username") }} />
        <Cell title="清理缓存" isLink={true} size="large" onClick={() => { 
          
          Dialog.confirm({
            title: '清理缓存',
            message: '',
          })
            .then(() => {
              clearData()
            })
            .catch(() => {
              console.log('catch');
            })
          
           }} />
        
      </Cell.Group>

      <Tabbar />
    </div>

  );
}