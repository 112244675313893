import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import SpaceProduct from "./pages/Shop/SpaceProduct";
import Login, { RequireAuth } from "./pages/Login";
import OrderResult from "./pages/Shop/SpaceProduct/orderResult";
import Book from "pages/Shop/SpaceProduct/book";
import WaterDetail from "pages/Shop/Product/water_detail";
import OrderList from "pages/Shop/SpaceProduct/orderList";
import ShopOrderList from "pages/Shop/Order/shopOrderList";
import OrderDetail from "pages/Shop/Order/orderDetail";
import AddressForm from "pages/Shop/Address/form";
import My from "pages/My";
import Deliver from "pages/Admin/Shop/Deliver";
import DeliverDetail from "pages/Admin/Shop/Deliver/deliverDetail";
import Overdue from "pages/Estate/Overdue";
import Room from "pages/Estate/Room";
import RoomBind from "pages/Estate/Room/bind";
import OverdueDetail from "pages/Estate/Overdue/detail";
import MyOrder from "pages/Estate/Order";
import Account from "pages/My/Account";
import RechargeOrder from "pages/My/Account/RechargeOrder";
import SpaceProductBookList from "pages/Admin/Shop/SpaceProductBookList";
import Password from "pages/My/Password";
import Username from "pages/My/Username";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route  element={<RequireAuth />} >
            <Route path="/shop/space-product/" element={<SpaceProduct />} />

            <Route path="/" element={<Home />} />
            <Route path="/shop/space-product/" element={<SpaceProduct />} />
            <Route path="/shop/space-product/book" element={<Book />} />
            <Route path="/shop/space-product/order" element={<OrderList />} />
            <Route
              path="/shop/space-product/order/:id"
              element={<OrderResult />}
            />
            <Route path="/shop/water/:id" element={<WaterDetail />} />
            <Route path="/shop/order/:id" element={<OrderDetail />} />
            <Route path="/shop/address/form" element={<AddressForm />} />
            <Route path="/my" element={<My />} />
            <Route path="/my/account-balance" element={<Account />} />
            <Route path="/my/password" element={<Password />} />
            <Route path="/my/username" element={<Username />} />
            <Route
              path="/order/recharge-order-detail"
              element={<RechargeOrder />}
            />

            <Route path="/shop/order" element={<ShopOrderList />} />
            <Route path="/estate/my-overdue-list" element={<Overdue />} />
            <Route path="/estate/my-room-list" element={<Room />} />
            <Route path="/estate/my-order" element={<MyOrder />} />
            <Route path="/estate/room-bind-request" element={<RoomBind />} />
            <Route path="/order/overdue-detail" element={<OverdueDetail />} />

            <Route path="/admin/shop/order-deliver" element={<Deliver />} />
            <Route
              path="/admin/shop/order-deliver/:id"
              element={<DeliverDetail />}
            />
            <Route
              path="/admin/shop/space-product-book"
              element={<SpaceProductBookList />}
            />

          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
