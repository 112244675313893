import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { clear } from 'redux-localstorage-simple'
import {
  Button,
  Card,
  Field,
  Flex,
  Loading,
  NavBar,
  Notify,
  Toast,
} from "react-vant";
import { AppState } from "state";
import { get_current_user, login, weixinlogin } from "../../state/user/reducer";
import { useAppDispatch, useAppSelector } from "../../state/hooks";

import { isWeixin } from "utils";
import { WECHAT_AUTH_URL } from "../../constants/urls";
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  let query = useQuery();
  const [isLoading, setIsLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { user, jwt, loginError } = useAppSelector(
    (state: AppState) => state.user
  );
  const code = query.get("code");
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleLogin = () => {
    dispatch(login({ username, password }));
  };

  //获取微信登录信息
  useEffect(() => {
    clear()
    if (isWeixin() && code) {
      dispatch(weixinlogin({ code }));
    }
  }, [code, dispatch]);

  useEffect(() => {
    if (jwt && user) {
      let to = localStorage.getItem("next");
      localStorage.removeItem("next");
      to = to ? to : "/";
      navigate(to, { replace: true });
    }
  }, [jwt, navigate, user]);

  useEffect(() => {
    if (loginError) {
      Notify.show(JSON.stringify(loginError));
    }
  }, [loginError]);

  return (
    <>
      {isLoading ? (
        <Loading color="#3f45ff" style={{ padding: "8px" }} />
      ) : (
        <>
          <NavBar
            title="用户登录"
            leftText="返回"
            rightText="按钮"
            onClickLeft={() => Toast("返回")}
            onClickRight={() => Toast("按钮")}
          />
          <Flex justify="center" align="center" style={{ height: "100vh" }}>
            <Flex.Item>
              <Card
                round
                style={{
                  marginBottom: "30vh",
                  marginRight: 20,
                  marginLeft: 20,
                }}
              >
                <Card.Body>
                  <Field
                    label="用户名"
                    placeholder="请输入用户名"
                    value={username}
                    onChange={(val) => setUsername(val)}
                  />
                  <Field
                    label="密码"
                    type="password"
                    placeholder="请输入密码"
                    value={password}
                    onChange={(val) => setPassword(val)}
                  />
                </Card.Body>
                <Card.Footer border>
                  <Button
                    type="primary"
                    size="large"
                    onClick={handleLogin}
                    loading={isLoading}
                  >
                    登录
                  </Button>
                </Card.Footer>
              </Card>
            </Flex.Item>
          </Flex>
        </>
      )}
    </>
  );
};

export function RequireAuth() {
  const { jwt } = useAppSelector((state: AppState) => state.user);
  let dispatch=useAppDispatch()
  
  useEffect(()=>{
      if(jwt){
        dispatch(get_current_user())
      }else {
        
        window.location.href = WECHAT_AUTH_URL
      }
  },[dispatch, jwt])

  // if (jwt === null) {
  //   localStorage.setItem("next", location.pathname);
  
  //   //Notify.show(WECHAT_AUTH_URL);
  //   if (isWeixin()) {
  //       console.log("redirct:",WECHAT_AUTH_URL)
  //       window.location.href = WECHAT_AUTH_URL
  //   //    return <Button onClick={()=>{window.location.href = WECHAT_AUTH_URL;}}>微信登陆</Button>
    
  //   } else {
  //     navigate("/login");
  //   }
  // }
  return <Outlet />;
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
