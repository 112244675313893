import {  MY_PASSWORD_URL, MY_USERNAME_URL } from "constants/urls";
import { User } from "types";

import { emptySplitApi } from "./emptySplitApi";

interface UpdatePasswordResponse {
  status:'ok'|'fail'
  msg:string
}

interface CodeLoginResponse {
  status:'ok'|'fail',
  data:{user:User,jwt:string}
}



const extendedApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    login:  build.mutation<CodeLoginResponse, string>({
      query: (password) => ({
        url: MY_PASSWORD_URL,
        body:{password},
        method: "get"     
      }),
    }),
    updatePassword:  build.mutation<UpdatePasswordResponse, string>({
      query: (password) => ({
        url: MY_PASSWORD_URL,
        body:{password},
        method: "put"     
      }),
    }),
    updateUsername:  build.mutation<UpdatePasswordResponse, string>({
        query: (username) => ({
          url: MY_USERNAME_URL,
          body:{username},
          method: "put"     
        }),
      }),
  }),
  overrideExisting: false,
});

export const {
  useUpdatePasswordMutation,
  useUpdateUsernameMutation
} = extendedApi;