import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Cell, Grid, Space, Typography } from 'react-vant';
import { AppState } from 'state';
import { useAppSelector } from 'state/hooks';



// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const {  user } = useAppSelector((state: AppState) => state.user)
  return (
    <>

      {user && user.is_active && user.is_staff ?
      <>
      <div style={{marginTop:20}}>
      <Cell.Group title={"工作面板"}>
       
        </Cell.Group>

<Grid gutter={10} style={{marginTop:8}}>
          <Grid.Item text="订单配送" >
            <Link to={"/admin/shop/order-deliver"}>
            <Typography.Text>订单配送</Typography.Text>
            </Link>
          </Grid.Item>
          <Grid.Item text="羽毛球场" >
            <Link to={"/admin/shop/space-product-book"}>
            <Typography.Text>羽毛球场</Typography.Text>
            </Link>
          </Grid.Item>
        </Grid>
        </div></> : null}
    </>
  );
};