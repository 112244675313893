import { GOOD_HOST } from "constants/config";
import { ADMIN_SHOP_MY_DELIVER_PROCESS_URL, ADMIN_SHOP_ORDER_DELIVER_URL } from "constants/urls";
import http from "network/http";
import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom";
import { Card, Cell, List, ListInstance, PullRefresh, Tabs } from "react-vant";
import { AppState } from "state";
import { useAppSelector } from "state/hooks";
import { OrderDeliver } from "types";



declare type Page = {
    current: number, total: number
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const unmountedRef = useRef<boolean>(false);
    const listRef = useRef<ListInstance>(null);
    const [page, setPage] = useState<Page | null>(null);
    const [finished, setFinished] = useState<boolean>(false);
    const [list, setList] = useState<Array<OrderDeliver>>([]);
    const [loading, setLoading] = useState(false)
    const [tabIndex, setTabIndex] = useState(0)

    const { jwt } = useAppSelector((state: AppState) => state.user);
    const onLoadRefresh = async (isRefresh?: number) => {
        setLoading(true)
        console.log("isRefresh:", isRefresh)
        console.info("onLoadRefresh call")
        var pageNumber = 1
        if (!isRefresh && page?.current) {
            pageNumber = page.current + 1
        }
        var url = ""
        if (tabIndex === 0) {
            url = `${GOOD_HOST}${ADMIN_SHOP_ORDER_DELIVER_URL}?process_code=deliver&count=5&status=open&page=${pageNumber}`
        } else if (tabIndex === 1) {

            url = `${GOOD_HOST}${ADMIN_SHOP_MY_DELIVER_PROCESS_URL}?process_code=deliver&page=${pageNumber}`
        }

        const res = await http.get(url,{headers:{"Authorization": 'Bearer ' + jwt}}
        )
        const results = res.data.results
        const _page = res.data.page
        setPage(_page)
        setList((v) => {
            const newList = isRefresh ? results : [...v, ...results];
            if (newList.length >= _page.total) {
                setFinished(true);
            }
            return newList;
        });
        setLoading(false)
    };

    useEffect(() => {
        var isRefresh = true
        setLoading(true)

        console.info("onLoadRefresh call")
        var pageNumber = 1

        var url = ""
        if (tabIndex === 0) {
            url = `${GOOD_HOST}${ADMIN_SHOP_ORDER_DELIVER_URL}?process_code=deliver&count=5&status=open&page=${pageNumber}`
        } else if (tabIndex === 1) {

            url = `${GOOD_HOST}${ADMIN_SHOP_MY_DELIVER_PROCESS_URL}?process_code=deliver&page=${pageNumber}`
        }

        http.get(url,{headers:{"Authorization": 'Bearer ' + jwt}}).then(res => {
            const results = res.data.results
            const _page = res.data.page
            setPage(_page)
            setList((v) => {
                const newList = isRefresh ? results : [...v, ...results];
                if (newList.length >= _page.total) {
                    setFinished(true);
                }
                return newList;
            });
            setLoading(false)
        })


    }, [tabIndex])


    const onRefresh = async () => {
        setFinished(false);
        await onLoadRefresh(1);
        listRef.current?.check();
    };

    useEffect(() => {
        return () => {
            unmountedRef.current = true;
        };
    }, []);

    return (
        <>
            <Tabs sticky swipeable onChange={(key: string | number, title: string) => {
                setTabIndex(key as number)

            }}>
                {['待配送', '我配送的'].map((item) => (
                    <Tabs.TabPane key={item} title={`${item}`}>

                    </Tabs.TabPane>

                ))}
            </Tabs>
            <PullRefresh onRefresh={onRefresh} >

                <List ref={listRef} finished={finished} loading={loading} onLoad={onLoadRefresh} >
                    {
                        list?.map((item, i) => {
                            return <Card key={i} style={{ marginBottom: 10 }}>
                                <Card.Body>
                                    <Cell title={"订单ID"} value={item.order?.id} />
                                    <Cell title={"配送状态"} value={item.status} />
                                    <Cell title={"配送地址"} value={item.order?.receiverAddress} />
                                    <Cell title={"配送商品"} value={item.order?.orderItems.map((v) => { return <p key={v.goodId}>{v.name} {v.goodSkuValues} 数量:{v.quantity}</p> })} />
                                    {tabIndex === 0 ? <Link to={`/admin/shop/order-deliver/${item.id}`}><Cell title={`接单`} isLink={true} /></Link> : null}
                                    {tabIndex === 1 ? <Link to={`/admin/shop/order-deliver/${item.id}`}><Cell title={`查看`} isLink={true} /></Link> : null}
                                </Card.Body>
                            </Card>;
                        })
                    }
                </List>

            </PullRefresh>

        </>
    )
}



