import {REDIRECT_URI,APPID} from './config'

export const LOGIN_URL=`/login`
export const GET_CURRENT_USER_URL=`/user/current`
export const SHOP_SAPCE_PRODUCT_LIST_URL=`/shop/space-product/product`
export const SHOP_SPACE_PRODUCT_ORDER_DETAIL_URL=`/shop/space-product/order`
export const SHOP_SPACE_PRODUCT_ORDER_URL=`/shop/space-product/order`
export const BALANCE_PAY_URL="/ledger/balance/pay/"
export const WEIXIN_LOGIN_URL="/weixin/login" //use code to get or create user
export const WEPAY_GET_PREPARE_ID="/ledger/wepay/get-prepare-id"
export const SHOP_ORDER_URL="/shop/order"
export const NEW_ORDER_ID_URL="/order/new-order-id"
export const GET_ORDER_URL="/order/get"
export const ADDRESS_DEFAULT_URL="/shop/address/default"

export const ESTATE_MY_OVERDUE_LIST_URL="/propertyfee/my/overdue-list"

export const ESTATE_MY_ORDER_LIST_URL="/propertyfee/my/order"
export const ESTATE_MY_ROOM_URL="/propertyfee/my/room-list"
export const ESTATE_MY_ROOM_BIND_REQUEST_URL="/propertyfee/my/room-bind-request"
export const ESTATE_ROOM_BIND_REQUEST_URL="/propertyfee/my/room-bind-request"
export const ESTATE_ROOM_OVERDUE_DETAIL_URL="/propertyfee/my/room-overdue-detail"
export const LEDGER_BALANCE_URL="/ledger/balance/default"
export const LEDGER_RECHARGE_WEWAY_URL="/ledger/recharge/wepay"

export const GOOD_LIST_URL="/shop/api/c/goodList"
export const GOOD_DETAIL_URL="/shop/api/c/goodDetail"  //goodId
export const SHOP_ADDRESS_GET_DEFAULT="/shop/api/c/address/get-default"
export const SHOP_ORDER_CREATE="/shop/api/c/order/create"
export const SHOP_ORDER_DETAIL="/shop/api/c/order"
export const SHOP_ORDER_LIST="/shop/api/c/order/list"
export const ADMIN_SHOP_ORDER_DELIVER_URL="/shop/api/s/deliver-process/"
export const ADMIN_SHOP_ORDER_DELIVER_ACCEPT_URL="/shop/api/s/deliver-process-accept"
export const ADMIN_SHOP_MY_DELIVER_PROCESS_URL="/shop/api/s/my-deliver-process"
export const ADMIN_SHOP_ORDER_DELIVER_CLOSE_URL="/shop/api/s/deliver-process-close"

export const ADMIN_SHOP_SPACE_PRODUCT_BOOK_LIST_URL="/shop-admin/space-product/book-list"

export const MY_PASSWORD_URL="/user/password"
export const MY_USERNAME_URL="/user/username"



export const WECHAT_AUTH_URL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`