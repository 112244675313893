import React from 'react';
import { ShippingAddress } from 'types';


interface AddressProps {
    value: ShippingAddress | null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: AddressProps) => {
    const { value } = props
    return (
        <>
          {value?<div><span style={{ paddingRight: 5 }} >{value?.address}</span><span style={{ paddingRight: 5 }}>{value?.consignee}({value?.phone})</span></div>:null}
        </>
    );
};