import { ADMIN_SHOP_SPACE_PRODUCT_BOOK_LIST_URL } from "constants/urls";
import { Page, SpaceProductBookInfo } from "types";
import { emptySplitApi } from "./emptySplitApi";

interface SpaceProductListResponse {
  results:SpaceProductBookInfo[],
  page:Page
}

interface DeleteResponse {
  code: "ok" | "fail"
}


const extendedApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getSpaceProductBookedList: build.query<SpaceProductListResponse, {page:number,status:string}>({
      query: ({page,status}) => `${ADMIN_SHOP_SPACE_PRODUCT_BOOK_LIST_URL}?page=${page}&status=${status}`,
    }),
 
    deleteTradeStrategy:  build.mutation<DeleteResponse, string>({
      query: (id) => ({
        url: `/trade/my-strategy/${id}`,
        method: "delete"
      }),
      invalidatesTags: ['SpaceProductBookList'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSpaceProductBookedListQuery
} = extendedApi;