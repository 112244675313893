import { useUpdatePasswordMutation } from "api/my_api";
import { API_HOST, HOST } from "constants/config";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Field, Flex, NavBar, Notify, Popup } from "react-vant";
import { AppState } from "state";
import { useAppSelector } from "state/hooks";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [balance, setBalance] = useState("");
  const [visible, setVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [rePassword, setRepassword] = useState("");
  const [disabled, setDisabled] = useState(false);

  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();

  let navigate = useNavigate();

  async function onFinish() {
    if(password===""){
      Notify.show({ type: 'danger', message: "请输入密码" });
    }
    if(password!==rePassword){
      Notify.show({ type: 'danger', message: "两次密码不一致！" });
    }
    const res = await updatePassword(password).unwrap()
    if (res.status === 'ok') {
      Notify.show({ type: 'success', message: '密码更新成功' });
      } else {
        Notify.show({ type: 'danger', message: res.msg });
      }
    }


  return (
    <>
      <NavBar
        title="修改密码"
        leftText="返回"
        onClickLeft={() => {
          navigate("/my");
        }}
      />
       <div style={{padding:"5px"}}>
      <Field label="新密码" type="password" placeholder="请输入新密码" onChange={(val) => {
        setPassword(val)
      }} />
      <Field
        label="重复新密码"
        type="password"
        placeholder="请重复输入新密码"
        onChange={(val) => { setRepassword(val)}}
      /><div style={{padding:"5px"}}>
      <Button type="primary" block round onClick={()=>{onFinish()}}>提交</Button>
      </div>
      </div>
    </>
  );
};


