
import { AxiosResponse } from 'axios';
import OrderDetail from 'components/OrderDetail';
import { API_HOST, HOST } from 'constants/config';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Cell, CountDown,  Loading, Notify,  Typography } from 'react-vant';
import { Card } from 'react-vant';
import { AppState } from 'state';
import { useAppSelector } from 'state/hooks';
import { Order } from 'types';
import {  WEPAY_GET_PREPARE_ID,  SHOP_ORDER_URL } from '../../../constants/urls';
import http from '../../../network/http';
declare const window: Window & { WeixinJSBridge: any };



// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false)

  const [order, setOrder] = useState<Order | null>(null)
  const [expireTime, setExpireTime] = useState<number>(0)
  const [showCountDown, setShowCountDown] = useState(false)
  const { jwt } = useAppSelector((state: AppState) => state.user);

  useEffect(() => {
    setIsLoading(true)
    http.get(`${API_HOST}${SHOP_ORDER_URL}/${id}`, {headers:{"Authorization": 'Bearer ' + jwt}}).then((res: AxiosResponse<any>) => {

      if (res.status === 200) {
        const order = res.data
        setOrder(order)

        const timettt = moment(order.payment_expired_at).toDate().getTime() - new Date().getTime()
        setExpireTime(timettt)

        if (order.status==='new' || order.status==='wait-pay') {
          setShowCountDown(true)
        }
        setIsLoading(false)
      }
    }
    )

  }, [id])



  const payment = (orderId: string, orderMoney: number) => {

    http.get(
      `${API_HOST}${WEPAY_GET_PREPARE_ID}?order_id=${orderId}&total_price=${orderMoney}`,{headers:{"Authorization": 'Bearer ' + jwt}}
    ).then(function (res: { data: any; }) {
      var data = res.data
      if (data.prepay_id) {
        var next = `${HOST}/shop/order/${orderId}`
        window.location.href = `http://hlwx.sztitan.com/ledger/wxpay/pay/?next=${next}&prepay_id=${data.prepay_id}`;
      }
      else {
        Notify(data.message)
      }

    }, function (err: { response: { data: any; }; }) {
      console.log(JSON.stringify(err.response.data))
    })

  }

  return (

    <div>
      {isLoading?<Loading color="#3f45ff" style={{ padding: "8px" }} />:null}
      {order ?
        <Card style={{ margin: 8 }}>
          <Card.Header> <Typography.Title level={3}>订单信息</Typography.Title></Card.Header>
          <Card.Body>

            <Cell title="订单ID" value={order.id} />
            <Cell title="订单金额" value={`${order?.total_price.toFixed(2)}元`} />
            <Cell title="付款状态" value={order?.payment_status_display} />
            <Cell  >
              <div>订单详情</div>
              <OrderDetail value={order} />
            </Cell>
            {showCountDown  ?
              <CountDown time={expireTime} millisecond format="mm:ss" onFinish={() => { setShowCountDown(false) }}>
                {(timeData) => (
                  <>
                    <Typography.Title level={4} style={{ padding: 20 }} type="danger">
                      剩余支付时间： {timeData.minutes}分{timeData.seconds}秒
                    </Typography.Title>
                    <Button type="primary" size="large" onClick={() => {payment(order.id, order.total_price)}
                    }>
                      微信支付
                    </Button>

                  </>
                )}
              </CountDown> : null}

            {order.memo ?

              <Cell title={order.memo} style={{ color: "red" }} center />
              : null
            }

          </Card.Body>
        </Card> : null}

    </div>
  );
};




