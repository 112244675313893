import { AxiosResponse } from 'axios';
import { API_HOST } from 'constants/config';
import moment from 'moment';
import React, {  useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Cell, Checkbox, Field, Flex, Form, Loading, Notify, Picker, Popup, Radio, Tabs } from 'react-vant';
import { Card } from 'react-vant';
import { Tag } from 'react-vant';
import { AppState } from 'state';
import { useAppSelector } from 'state/hooks';

import { SHOP_SAPCE_PRODUCT_LIST_URL } from '../../../constants/urls';
import http from '../../../network/http';

const DATE_FORMAT = "YYYY-MM-DD"
interface SpaceProduct {
  id: number,
  name: string
}

interface BookInfo {
  id: number,
  start_time: string,
  end_time: string,
  price:number,
  available: boolean
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  let navigate = useNavigate();
  const formRef = useRef(null)
  const [products, setProducts] = useState<SpaceProduct[] | null>(null)
  const [bookProduct, setBookProduct] = useState<SpaceProduct | null>(null)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showNote,setShowNote]=useState(false)

  const [showTimePicker, setShowTimePicker] = useState(false)

  const [bookDate, setBookDate] = useState(new Date())

  const [timeList, setTimeList] = useState<BookInfo[]>([])
  const [bookIDs, setBookIDs] = useState<number[]>([])
  const [dateList, setDateList] = useState<string[]>([])
  const { jwt } = useAppSelector((state: AppState) => state.user);

  const [form] = Form.useForm();


  useEffect(() => {
    const minDate = new Date()
    const tempList = []
    for (var i = 0; i < 7; i++) {
      const datestr = moment(minDate).add(i, 'days').format(DATE_FORMAT)
      tempList.push(datestr)
      setDateList(tempList)

    }
  }, [])

  const onFinish = (values: any) => {
    if (!isLoading) {
      setIsLoading(true)
      http.post(API_HOST + "/shop/space-product/buy", { ...values, productName: bookProduct?.name, bookIDs },{headers:{"Authorization": 'Bearer ' + jwt}}).then((res: AxiosResponse<any>) => {
        const result = res.data
    
        if (result["code"] === "FAIL") {
          const msg = result["message"]
          Notify.show(JSON.stringify(msg))
          setIsLoading(false)
        } else if (result["code"] === "SUCCESS") {

          const id = result["data"]["order_id"]
          navigate(`/shop/space-product/order/${id}`)
        }
      }
      ).catch((err) => {
        console.info(err)
        setIsLoading(false)
      })
    }
  };

  useEffect(() => {
    const datestr = moment(bookDate).format(DATE_FORMAT)
    form.setFieldsValue({ bookDate: datestr })
    http.get(API_HOST + SHOP_SAPCE_PRODUCT_LIST_URL,{headers:{"Authorization": 'Bearer ' + jwt}})
      .then((res: AxiosResponse<any>) => {
        const result = res.data.data;
        var rrr: SpaceProduct[] = []
        result.forEach((element: any) => {
          rrr.push({ id: element.id, name: element.name})
        });
        setProducts(rrr);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (products && products.length>0) {
      setBookProduct(products[0])
      form.setFieldsValue({ productId: products[0].id })    }
  }, [form, products])

  useEffect(() => {
    const datestr = moment(bookDate).format(DATE_FORMAT)
    form.setFieldsValue({ bookDate: datestr })
    if (bookProduct) {
      console.log("jsss",jwt)
      http.get(`${API_HOST}/shop/space-product/book-info?book_date=${datestr}&product_id=${bookProduct.id}`,{headers:{"Authorization": 'Bearer ' + jwt}})
        .then((res: AxiosResponse<any>) => {
          console.info(res.data)
          setTimeList(res.data.result);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  }, [bookDate, bookProduct, form, jwt]);

  return (
    <>
  {products?

      <Cell.Group title={"江南岸羽毛球馆预定"}>

        {products.length===0?<Cell style={{ margin: 5 }}>暂未开放</Cell>:

        <Card style={{ margin: 5 }}>
          <Form
            form={form}
            showValidateMessage={false}
            onFinish={onFinish}
            footer={
              <div style={{ margin: '16px 16px 15px' }}>
                <Button round nativeType="submit" type="primary" block loading={isLoading} disabled={isLoading}>
                  提交
                </Button>
              </div>
            }
            ref={formRef}
          >
            <Form.Item
              name="productId"
              label="场馆"
            >
              <Radio.Group >
                {products?.map((val, index) => {
                  return <Radio name={val.id} key={index}>{val.name}</Radio>

                })}

              </Radio.Group>

            </Form.Item>
            {/* <Cell><Button>选择日期</Button></Cell> */}
            <Form.Item

              initialValue={moment(bookDate).format(DATE_FORMAT)}
              rules={[{ required: true, message: '请选择选择日期' }]}
              name="bookDate"
              label="日期"
              onClick={() => setShowDatePicker(true)}
            >
              <Field
                readonly
                clickable
                placeholder="选择选择日期"

              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: '选择选择时间' }]}
              name="bookTime"
              label="时间"
              onClick={() => setShowTimePicker(true)}
            >
              <Field
                readonly
                clickable
                rows={1}
                autosize
                placeholder="选择选择时间"
              />
            </Form.Item>
            <Form.Item name="totalPrice" label="金额">
              <Field
                readonly

                rightIcon={'元'}
              />
            </Form.Item>
            <Form.Item name="contactName" label="联系人" rules={[{ required: true, message: '请输入名称' }]}>
              <Field
                label="联系人"
                placeholder="输入名称"
              />
            </Form.Item>

            <Form.Item name="contactNumber" label="联系电话" initialValue={''}
              rules={[{ required: true, message: '请输入联系电话' }]}
            >
              <Field

                label="联系电话"

                placeholder="输入联系电话"
              />
            </Form.Item>
            <Form.Item name="numberOfPeople" label="人数" initialValue={''} rules={[{ required: true,type: 'number' ,validator: (_, value) => {
              if (value<=6) {
                return Promise.resolve(true);
              }
              return Promise.reject(new Error('不能大于6'));
            }, }]} showValidateMessage={true}>
              <Field
  
                label="人数"
                placeholder="输入人数"
                rightIcon={'人'}
              />
            </Form.Item>
            <Form.Item name="contactNumber"  initialValue={''}
              rules={[{ required: true, message: '请确定已阅读' }]}
            >

<Checkbox  shape="square">
       
      </Checkbox>
      <span onClick={()=>{setShowNote(true);}} style={{marginLeft:"20px"}}>订场须知</span>
            </Form.Item>
          </Form>

        </Card>}
      </Cell.Group>:<Loading color="#3f45ff" style={{ padding: "8px" }} />
}
      <Popup
        visible={showDatePicker}
        style={{ height: '40%' }}
        position="bottom"

      >
        <Picker
          title="请选择日期"

          columns={dateList}
          onChange={(val: string | number, index?: number | undefined) => {
            const dd = moment(val, DATE_FORMAT).toDate()
            const cc = moment(val, DATE_FORMAT).format(DATE_FORMAT)
            form.setFieldsValue({ bookDate: cc })
            setBookDate(dd)

          }}
          onCancel={() => {
            setShowDatePicker(false)
          }}
          onConfirm={() => {
            setShowDatePicker(false)
          }}
        />
      </Popup>
      <Popup
        round
        title="请选择场次"
        closeable
        visible={showTimePicker}
        position="bottom"
        onClose={() => setShowTimePicker(false)}
        style={{ paddingBottom: '8px' }}
      >
        <div style={{ padding: '8px' }}>
          <Checkbox.Group defaultValue={[]} direction="horizontal" onChange={(v: number[]) => {
            var bookTime: string[] = []
            var bookIDs: number[] = []
            var totalPrice=0
            v.forEach((i, index) => {
              bookTime.push(`${timeList[i].start_time}-${timeList[i].end_time}`)
              bookIDs.push(timeList[i].id)
              totalPrice=totalPrice+timeList[i].price
            })
            setBookIDs(bookIDs)
            form.setFieldsValue({ bookTime })
            if (bookProduct) {

              form.setFieldsValue({ totalPrice })
            }
          }}>
            <Flex justify="start" align="start" gutter={16} wrap="wrap" >

              {timeList?.map((item, index) => {
                return <Flex.Item  key={index}><Tag plain type="primary" style={{ padding: '4px', margin: '4px', fontSize:"16px" }}><Checkbox shape="square" name={index} disabled={!item.available}>{item.start_time}-{item.end_time} {item.price}元</Checkbox></Tag></Flex.Item>
              })}
              {!timeList || timeList.length === 0 ? <Flex.Item style={{ height: "150px" }}>无可定场次</Flex.Item> : null}

            </Flex>
          </Checkbox.Group>
        </div>
      </Popup>

      
      <Popup
        visible={showNote}
        closeable
        title="羽毛球馆入馆须知"
        descrition=""
        style={{ height: '90%' }}
        position="bottom"
        round
        onClose={() => setShowNote(false)}
      >
       <div style={{fontSize:"14px",paddingLeft:"15px",paddingRight:"15px"}}> 进入本馆运动健身者，请自觉维护馆内秩序，服从场地工作人员的管理。<br/>
        严禁携带易燃易爆物品、管制刀具进入场馆，禁止将宠物带入馆内。<br/>
        进入场地须穿软底运动鞋（皮鞋、钉鞋、拖鞋、雨鞋、高跟鞋不得入内），禁止黑色胶底鞋入场打球，请尽量穿专业羽毛球鞋。<br/>
        未成年人、老人需在监护人的陪同照顾下入场，预防意外伤害。<br/>
        身体患有心脏病、哮喘、高血压等一系列不适宜进行羽毛球运动的顾客禁止进入场地活动。<br/>
        请妥善保管好自己的随身物品，谨防遗失，若有丢失，场馆概不负责。<br/>
        请遵守文明规范，禁止乱扔垃圾，保持场馆整洁，共同维护文明、清洁的健身环境，请将废弃物放入垃圾桶。<br/>
        场馆内禁止用餐（包括零食）和饮用碳酸饮料、严禁吸烟、使用明火、随地吐痰、嚼口香糖、大声喧哗、嬉戏打闹、赤脚或赤膊上身等不文明行为。禁止在地面、墙面、桌椅上随意刻画、涂写。<br/>
        进入场馆人员必须严格遵守时间规定，到时请自觉离场，违反管理规定者，管理人员有权要求其离场。<br/>
        请爱护馆内所有设施设备，不要用身体倚靠在球网上，如有损坏，需照价赔偿。<br/>
        本须知在馆内公示，凡已进入场馆的顾客，均视为已阅读并承诺遵守上述管理规定，凡违反管理规定者，管理人员有权终止其使用权。<br/>
        收费标准：<br/>
        10:00-18:00 30元/小时<br/>
        18:00-21:00 40元/小时<br/>
        请登陆【泓利博德物业】公众号进行场次预订。<br/>
        联系电话:0756-3923770。<br/>
        </div>
              </Popup>
    </>
  );
};