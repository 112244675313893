import axios from "axios";
import { API_HOST } from "constants/config";


const instance = axios.create({
  baseURL:API_HOST,
});

instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
)

 export const clients={
   
    default: {
      client: instance
    }
  }