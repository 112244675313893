import axios from "axios";

  const request = axios.create();

  request.interceptors.request.use(function (config) {
    // Do something before request is sent

    // config.headers={"Authorization": 'Bearer ' + localStorage.getItem("jwt")}

    return config;
  }, function (error) {
    // Do something with request error

    return Promise.reject(error);
  });

  request.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response) {

        // switch (error.response.status) {
        //   case 401:
        //     window.location.href="/login"
        // }
      }

    return Promise.reject(error);
  });




// Override timeout default for the library
// Now all requests using this instance will wait 2.5 seconds before timing out
request.defaults.timeout = 2500;
export default request
