import { useGetSpaceProductBookedListQuery,  } from "api/space_product_api";
import { GOOD_HOST } from "constants/config";
import { ADMIN_SHOP_MY_DELIVER_PROCESS_URL, ADMIN_SHOP_ORDER_DELIVER_URL } from "constants/urls";
import { stat } from "fs";
import http from "network/http";
import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom";
import { Card, Cell, List, ListInstance, PullRefresh, Tabs } from "react-vant";
import { AppState } from "state";
import { useAppSelector } from "state/hooks";
import { OrderDeliver, Page, SpaceProductBookInfo } from "types";





// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const unmountedRef = useRef<boolean>(false);
    const listRef = useRef<ListInstance>(null);
    const [page, setPage] = useState<Page | null>(null);
    const [finished, setFinished] = useState<boolean>(false);
    const [list, setList] = useState<Array<SpaceProductBookInfo>>([]);
    const [loading, setLoading] = useState(false)
    const [pageNumber,setPageNumber]=useState(1)
    const [tabIndex, setTabIndex] = useState(0)
    const [status,setStatus]=useState("ordered")
    const { data, error, isLoading } = useGetSpaceProductBookedListQuery({page:pageNumber,status});
    const onLoadRefresh = async (isRefresh?: number) => {
        setLoading(true)
        var pageNumber = 1
        if (!isRefresh && page?.current) {
            pageNumber = page.current + 1
            setPageNumber(pageNumber)
        }
        if (tabIndex === 0) {
            setStatus("ordered")
        } else if (tabIndex === 1) {
            setStatus("avail")
        }
        const results = data?.results
        const _page = data?.page
        if(_page && results){
            setPage(_page)
            setList((v) => {
                const newList = isRefresh ? results : [...v, ...results];
                if (newList.length >= _page.total) {
                    setFinished(true);
                }
                return newList;
            });
        }
 
        setLoading(false)
    };

    useEffect(() => {
        var isRefresh = true
        setLoading(true)
        console.info("onLoadRefresh call")
        var pageNumber = 1
        setPageNumber(pageNumber)
        if (tabIndex === 0) {
            setStatus("ordered")
        } else if (tabIndex === 1) {
            setStatus("avail")
        }
    }, [tabIndex])


    const onRefresh = async () => {
        setFinished(false);
        await onLoadRefresh(1);
        listRef.current?.check();
    };

    useEffect(() => {
        return () => {
            unmountedRef.current = true;
        };
    }, []);

    return (
        <>
            <Tabs sticky swipeable onChange={(key: string | number, title: string) => {
                setTabIndex(key as number)

            }}>
                {['已定场地', '可定场地'].map((item) => (
                    <Tabs.TabPane key={item} title={`${item}`}>

                    </Tabs.TabPane>

                ))}
            </Tabs>
            <PullRefresh onRefresh={onRefresh} >

                <List ref={listRef} finished={finished} loading={loading} onLoad={onLoadRefresh} >
                    {
                        list?.map((item, i) => {
                            return <Card key={i} style={{ marginBottom: 10 }}>
                                <Card.Body>
                                    <Cell title={"开始时间"} value={item.start_time} />
                                    <Cell title={"结束时间"} value={item.end_time} />
                                    {status==="ordered"?<>
                                    <Cell title={"联系人"} value={item.contact_name} />
                                    <Cell title={"联系电话"} value={item.contact_number} />
                                    <Cell title={"订单id"} value={item.order_id} /></>
                                    :null}
                             
            
                                </Card.Body>
                            </Card>;
                        })
                    }
                </List>

            </PullRefresh>

        </>
    )
}



