import { API_HOST } from "constants/config";
import {  ESTATE_MY_ROOM_BIND_REQUEST_URL, ESTATE_MY_ROOM_URL } from "constants/urls";
import http from "network/http";
import { useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import { Card, Cell, List, ListInstance, NavBar, PullRefresh, Tabs, Toast } from "react-vant";
import { AppState } from "state";
import { useAppSelector } from "state/hooks";
import { Room ,RoomBindRequest} from "types";



declare type Page = {
  current: number, total: number
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  let navigate = useNavigate();
  const unmountedRef = useRef<boolean>(false);
  const listRef = useRef<ListInstance>(null);
  const [page, setPage] = useState<Page | null>(null);
  const [finished, setFinished] = useState<boolean>(false);
  const [roomList, setRoomList] = useState<Array<Room>>([]);
  const [roomBindRequestList, setRoomBindRequestList] = useState<Array<RoomBindRequest>>([]);
  const { jwt } = useAppSelector((state: AppState) => state.user);
  const [loading, setLoading] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)


  const getData=async (isRefresh?: boolean)=>{
    setLoading(true)
    console.log("isRefresh:", isRefresh)
    console.info("onLoadRefresh call")
    var pageNumber = 1
    if (!isRefresh && page?.current) {
      pageNumber = page.current + 1
    }

    var url = ""
    if (tabIndex === 0) {
      url = `${API_HOST}${ESTATE_MY_ROOM_URL}?page=${pageNumber}`
      const res = await http.get(url,{headers:{"Authorization": 'Bearer ' + jwt}})
      const results = res.data.results
      const _page = res.data.page
      setPage(_page)
      setRoomList((v) => {
          const newList = isRefresh ? results : [...v, ...results];
          if (newList.length >= _page.total) {
              setFinished(true);
          }
          return newList;
      });
      setLoading(false)
    } else if (tabIndex === 1) {

      url = `${API_HOST}${ESTATE_MY_ROOM_BIND_REQUEST_URL}?process_code=deliver&page=${pageNumber}`
      const res = await http.get(url,{headers:{"Authorization": 'Bearer ' + jwt}})
      const results = res.data.results
      const _page = res.data.page
      setPage(_page)
      setRoomBindRequestList((v) => {
          const newList = isRefresh ? results : [...v, ...results];
          if (newList.length >= _page.total) {
              setFinished(true);
          }
          return newList;
      });
      setLoading(false)
    }

  }

  const onLoadRefresh = async (isRefresh?: boolean) => {
      await getData(isRefresh)
  }


  useEffect(() => {
     getData(true)

}, [tabIndex])

  const onRefresh = async () => {
    setFinished(false);
    await onLoadRefresh(true);
    listRef.current?.check();
  };

  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);





  return (
    <>
      <NavBar
        title="房间绑定"
        leftText="返回"
        rightText="添加"
        onClickLeft={() => { navigate("/my") }}
        onClickRight={() => { navigate("/estate/room-bind-request") }}
      />
      <Tabs sticky swipeable onChange={(key: string | number, title: string) => {
        setTabIndex(key as number)

      }}>
        {['已绑房间', '待绑定房间'].map((item) => (
          <Tabs.TabPane key={item} title={`${item}`}>

          </Tabs.TabPane>

        ))}
      </Tabs>
      <PullRefresh onRefresh={onRefresh} >
        <Cell.Group title={""}>
        </Cell.Group>
        <List ref={listRef} finished={finished} loading={loading} onLoad={onLoadRefresh} >
          {
            tabIndex===0 && roomList?.map((item, i) => {
              return <Card key={i} style={{ marginBottom: 10 }}>
                <Card.Body>
                  <Cell title={`${item.project?.name} ${item.building?.name} ${item.code}`} />
                </Card.Body>
              </Card>;
            })
          }

{
            tabIndex===1 && roomBindRequestList?.map((item, i) => {
              return <Card key={i} style={{ marginBottom: 10 }}>
                <Card.Body>
                  <Cell title={`${item.room_code}`} />
                </Card.Body>
              </Card>;
            })
          }

        </List>

      </PullRefresh>

    </>
  )
}



