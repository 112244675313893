import DeliverItem from 'components/DeliverItem';

import { DJSOrder } from 'types';


interface Props {
    value: DJSOrder
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: Props) => {
    const { value } = props
    return (
        <>
         {value.orderItems?.map((val)=>{return <DeliverItem value={val} key={val.id} />})}
        </>
    );
};


