import { API_HOST } from "constants/config";
import { SHOP_SPACE_PRODUCT_ORDER_URL } from "constants/urls";
import { wrap } from "module";
import http from "network/http";
import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom";
import { Card, Cell, Divider, Flex, List, ListInstance, PullRefresh, Toast } from "react-vant";
import { AppState } from "state";
import { useAppSelector } from "state/hooks";


declare type Page={
current:number,total:number
}
declare type SpaceProductBook={
  bookDate:string
  bookTime:string[]
  productName:string
}
declare type SpaceProductOrder={
  id:string,
  total_price:number
  detail:{data:SpaceProductBook}
  }

  declare type MutilRowFlexProps={
    data:string[]
    title:string
    }

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const unmountedRef = useRef<boolean>(false);
  const listRef = useRef<ListInstance>(null);
  const [page, setPage] = useState<Page|null>(null);
  const [finished, setFinished] = useState<boolean>(false);
  const [list, setList] = useState<Array<SpaceProductOrder>>([]);
  const [loading,setLoading]=useState(false)
  const { jwt } = useAppSelector((state: AppState) => state.user);

  const onLoadRefresh = async (isRefresh?: number) => {
    setLoading(true)
   console.log("isRefresh:",isRefresh)
    console.info("onLoadRefresh call")
    var pageNumber=1
    if(!isRefresh && page?.current){
      pageNumber= page.current+1
    }

   const  res= await http.get(
      `${API_HOST}${SHOP_SPACE_PRODUCT_ORDER_URL}?page=${pageNumber}`,{headers:{"Authorization": 'Bearer ' + jwt}}
    )

    const results=res.data.results
    const _page=res.data.page
    setPage(_page)
    setList((v) => {
      const newList = isRefresh ? results : [...v, ...results];
      if (newList.length >= _page.total) {
        setFinished(true);
      }
      console.info("newlist length:",newList.length)
      console.info("_page :",_page)
      
      return newList;
    });
    setLoading(false)


  };

  const onRefresh = async () => {
    setFinished(false);
    await onLoadRefresh(1);
    listRef.current?.check();
  };

  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  const MutilRowFlex=(props:MutilRowFlexProps)=>{
    return (
      <>
   
      <div className='rv-cell__title' style={{padding:"10px 16px 0 16px"}}>{props.title}</div>
      <div style={{color:"#969799"}} className={"rv-cell"}>
  
       <Flex gutter={4} wrap={"wrap"} justify={"end"} style={{fontFamily: 'Helvetica Neue'}} >{props.data.map((val,index)=>{return<Flex.Item span={8} key={index} style={{textAlign:"right"}} ><div>{val}</div></Flex.Item>})}</Flex>
       </div>
      </>
     
    )
  }
  return (
    <>
       
      <PullRefresh onRefresh={onRefresh} >
      <Cell.Group title={"订场历史"}>
      </Cell.Group>
        <List ref={listRef} finished={finished} loading={loading}  onLoad={onLoadRefresh} >
          {
          list?.map((item, i) => {
              return <Card key={i} style={{marginBottom:10}}> 
               <Card.Body>
                  <Cell title={"订单ID"} value={item.id} />
                  <Cell title={"金额"} value={item.total_price} />
                  <Cell title={"场馆"} value={item.detail.data.productName} /> 
                  <Cell title={"日期"} value={item.detail.data.bookDate} />
                  {item.detail.data.bookTime.length==1?
                  <Cell title={"场次"} value={item.detail.data.bookTime} />
                  :<MutilRowFlex data={item.detail.data.bookTime} title={'场次'} />}
                  <Link to={`/shop/space-product/order/${item.id}`}><Cell  title={`查看详情`} isLink={true}/></Link>
                  </Card.Body>
                  </Card>;
            })
            }
        </List>
  
      </PullRefresh>

    </>
  )
}



