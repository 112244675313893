import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Cell, Typography } from "react-vant";
import { CellGroup } from 'react-vant/es/cell';

export default () => {
  let navigate = useNavigate();

  return (
    <div>

      <Cell.Group title="羽毛球馆"  >
        <Cell title="羽毛球馆订场" isLink={true} size="large" onClick={() => { navigate("/shop/space-product/book") }} />
        <Cell title="订场历史" isLink={true} size="large" onClick={() => { navigate("/shop/space-product/order") }} />
      </Cell.Group>
    </div>
  );
}