
import DJSOrderDetailCard from "components/DeliverOrderCard";
import { GOOD_HOST } from "constants/config";
import { ADMIN_SHOP_ORDER_DELIVER_ACCEPT_URL, ADMIN_SHOP_ORDER_DELIVER_CLOSE_URL, ADMIN_SHOP_ORDER_DELIVER_URL  } from "constants/urls";
import http from "network/http";
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { Button, Card, Cell, Dialog, Loading, Typography } from "react-vant";
import { AppState } from "state";
import { useAppSelector } from "state/hooks";
import { OrderDeliver } from "types";


// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState('')
    const [orderDeliver, setOrderDeliver] = useState<OrderDeliver | null>(null)
    const { jwt } = useAppSelector((state: AppState) => state.user);
    useEffect(() => {
        setLoading(true)
        http.get(
            `${GOOD_HOST}${ADMIN_SHOP_ORDER_DELIVER_URL}${id}`,{headers:{"Authorization": 'Bearer ' + jwt}}
        ).then((val) => {
            const data = val.data
            setOrderDeliver(data)
            setStatus(data.status)
            setLoading(false)
        })

    }, [id, jwt, status])

    function handleAccept() {



        Dialog.confirm({
            title: '配送接单',
            message: '确定配送此订单？',
        }).then(val => {
            if (val === true) {
                http.post(
                    `${GOOD_HOST}${ADMIN_SHOP_ORDER_DELIVER_ACCEPT_URL}`, { id },{headers:{"Authorization": 'Bearer ' + jwt}}
                ).then((val) => {
                    setStatus("active")
                })
            }
        })

    }

    function handleClose() {

        Dialog.confirm({
            title: '配送状态',
            message: '确定已完成配送？',
        }).then(val => {
            if (val === true) {
                http.post(
                    `${GOOD_HOST}${ADMIN_SHOP_ORDER_DELIVER_CLOSE_URL}`, { id },{headers:{"Authorization": 'Bearer ' + jwt}}
                ).then((val) => {
                    setStatus("close")
                })
            }
        })


    }

    return (
        <>{!loading ? <>
            <Card style={{ margin: 8 }}>
                <Card.Header> <Typography.Title level={3}>配送接单</Typography.Title></Card.Header>
                <Card.Body>
                    <Cell title="订单ID" value={orderDeliver?.order.id} />
                    <Cell title="配送状态" value={orderDeliver?.status} />
                    <Cell title="配送地址" value={orderDeliver?.order.receiverAddress} />
                    <Cell title="联系人" value={orderDeliver?.order.receiverConsignee} />
                    <Cell title="联系电话" value={orderDeliver?.order.receiverPhone} />
                    <div style={{padding:"10px 16px"}}>
                        <b>商品</b>
                    {orderDeliver?.order?<DJSOrderDetailCard value={orderDeliver.order} />:null }
      
                    </div>

                    {orderDeliver?.status === 'open' ? <Button type="primary" size="large" onClick={() => {
                        handleAccept()
                    }
                    }>接单配送</Button> : null}

                    {orderDeliver?.status === 'active' ? <Button type="primary" size="large" onClick={() => {
                        handleClose()
                    }
                    }>配送中
                    </Button> : null}
                    {orderDeliver?.status === 'close' ? <Button type="primary" size="large"
                    >已完成
                    </Button> : null}

                </Card.Body>
            </Card>

        </> : <Loading color="#3f45ff" style={{ padding: "8px" }} />}
        </>
    )
}



