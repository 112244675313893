import { API_HOST, HOST } from 'constants/config';
import {  LEDGER_BALANCE_URL, LEDGER_RECHARGE_WEWAY_URL, WEPAY_GET_PREPARE_ID } from 'constants/urls';
import http from 'network/http';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Field, Flex, NavBar, Notify, Popup, } from "react-vant";
import { AppState } from 'state';
import { useAppSelector } from 'state/hooks';


// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [balance, setBalance] = useState('')
  const [visible, setVisible] = useState(false)
  const [amount, setAmount] = useState(0)
  const [disabled, setDisabled] = useState(false)
  const { jwt } = useAppSelector((state: AppState) => state.user);
  let navigate = useNavigate();
  async function getBalance() {
    const res = await http.get(
      `${API_HOST}${LEDGER_BALANCE_URL}`,{headers:{"Authorization": 'Bearer ' + jwt}}
    )
    if (res.status === 200) {
      setBalance(res.data.balance)
    }
  }

  useEffect(() => {
    getBalance()
  }, [])

  async function onFinish() {
    setDisabled(true)
    const url = `${API_HOST}${LEDGER_RECHARGE_WEWAY_URL}`
    const res = await http.post(url, { amount },{headers:{"Authorization": 'Bearer ' + jwt}})
    if (res.status === 200) {
      const orderId = res.data.id
      const res2 = await http.get(
        `${API_HOST}${WEPAY_GET_PREPARE_ID}?order_id=${orderId}&total_price=${amount}`,{headers:{"Authorization": 'Bearer ' + jwt}}
      )
      var data = res2.data
      if (data.prepay_id) {
        var next = encodeURIComponent(`${HOST}/order/recharge-order-detail?id=${orderId}`)
        window.location.href = `http://hlwx.sztitan.com/ledger/wxpay/pay/?next=${next}&prepay_id=${data.prepay_id}`;
      }
      else {
        Notify(data.message)
      }
    }
  }

  return (
    <div>
      <NavBar
        title=""
        leftText="返回"
        onClickLeft={() => { navigate("/my") }}
      />
      <Flex justify="center" align="center" direction="column" >
        <Flex.Item><div style={{ height: "100px", fontSize: "48px", marginTop: "20vh" }}>¥ {balance} 元</div></Flex.Item>
        <Flex.Item><div style={{ width: "40vw" }}><Button type='primary' block onClick={() => { setVisible(true) }} >充值</Button></div></Flex.Item>
      </Flex>
      <Popup
        visible={visible}
        closeable
        style={{ height: '300px' }}
        position="bottom"
        round
        onClose={() => setVisible(false)}
      >
        <Card >
          <Card.Header>输入充值金额</Card.Header>
          <Card.Body>
            <div style={{ marginTop: 20 }}>
              <Field
                label="金额"
                size="large"
                placeholder="请输入金额"
                rightIcon={'元'}
                border={true}
                onChange={(val) => { setAmount(parseFloat(val)) }}
                type='number'
              />
            </div>
            <div style={{ marginTop: 30 }}>
              <Button block type='primary' onClick={() => { onFinish() }} disabled={disabled}>确定</Button>
            </div>
          </Card.Body>
        </Card>
      </Popup>
    </div>

  );
}