import { createAction } from '@reduxjs/toolkit'
import { AxiosRequestConfig, AxiosResponse } from 'axios';

// fired once when the app reloads but before the app renders
// allows any updates to be applied to store data loaded from localStorage
//export const login = createAction<{username:string,password:string}>('user/login')
export const logout = createAction<{ username: string, password: string }>('user/logout')

export type Action =
    {
        type: 'LOGIN',
        payload: {
            client: string,
            request: AxiosRequestConfig
        }
    } | {
        client: string,
        type: 'LOGIN_SUCCESS',
        payload: AxiosResponse
    } | {
        client: string,
        type: 'LOGIN_FAIL',
        error: { response: AxiosResponse }
    } | {
        client: string,
        type: 'FETCH_SPACE_PRODUCT_ORDER_LIST',
        payload: {
            request: AxiosRequestConfig
        }
    } | {
        type: 'WEIXIN_LOGIN',
        payload: {
            client: string,
            request: AxiosRequestConfig
        }
    } | {
        client: string,
        type: 'WEIXIN_LOGIN_SUCCESS',
        payload: AxiosResponse
    } | {
        client: string,
        type: 'WEIXIN_LOGIN_FAIL',
        error: { response: AxiosResponse }
    } | {
        type: 'GET_CURRENT_USER',
        payload: {
            client: string,
            request: AxiosRequestConfig
        }
    }
    | {
        client: string,
        type: 'GET_CURRENT_USER_SUCCESS',
        payload: AxiosResponse
    } | {
        client: string,
        type: 'GET_CURRENT_USER_FAIL',
        error: { response: AxiosResponse }
    } |{

        type: 'TABBAR_INDEX_CHANGE',
        payload: { tabbarIndex: number }

    }
    ;

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const WEIXIN_LOGIN = "WEIXIN_LOGIN"
export const WEIXIN_LOGIN_FAIL = "WEIXIN_LOGIN_FAIL"
export const WEIXIN_LOGIN_SUCCESS = "WEIXIN_LOGIN_SUCCESS"
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS"
export const GET_CURRENT_USER_FAIL = "GET_CURRENT_USER_FAIL"

export const GET_CURRENT_USER="GET_CURRENT_USER"

export const TABBAR_INDEX_CHANGE = "TABBAR_INDEX_CHANGE"

